import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import * as styles from "./style.module.scss";
import {StaticImage} from "gatsby-plugin-image";

import {ButtonUI, NoGapUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {BackgroundPosterCt, ButtonOfScrollCt, Layout} from "@components";

import {Link} from "gatsby";
import styled from "styled-components";

import sRubleImg from "@images/partner/service-ruble.png";
import sUpperImg from "@images/partner/service-upper.png";
import sDocsImg from "@images/partner/service-docs.png";
import sPrivateImg from "@images/partner/service-private.png";

import num1Img from "@images/partner/num-1.png";
import num2Img from "@images/partner/num-2.png";
import num3Img from "@images/partner/num-3.png";

import {useWindowSize} from "@tools/hooks";

const tableOrganization = {
    header: ['Оборот клиента за месяц', 'Комиссия сервиса', 'Ваша комиссия', 'Ваш средний годовой доход за одного клиента'],
    col1: ['до 2 млн ₽', '2 — 5 млн ₽', '5 — 20 млн ₽', '20 — 50 млн ₽', 'от 50 млн ₽'],
    col2: ['3,00 %', '2,50 %', '2,00 %', '1,50 %', '1,00 %'],
    col3: ['0,55 %', '0,40 %', '0,25 %', '0,20 %', '0,10 %'],
    col4: ['от 66 000 ₽', 'от 150 000 ₽', 'от 250 000 ₽', 'от 400 000 ₽', 'от 600 000 ₽'],
}

const tableIndividual = {
    header: ['Оборот приведенного клиента за месяц', 'Ваш доход за три месяца за одного клиента'],
    col1: ['до 2 млн ₽', '2 — 5 млн ₽', '5 — 20 млн ₽', '20 — 50 млн ₽', 'от 50 млн ₽'],
    col2: ['до 50 000 ₽', 'до 100 000 ₽', 'до 150 000 ₽', 'до 200 000 ₽', 'до 250 000 ₽'],
}

const PartnerQuiz: React.FC<{}> = ({}) => {

    useEffect(() => {
        const mainUrl = "/promo/iframe-quiz/partner";
        const urlParams = window.location.search;
        const iframeUrl = mainUrl + urlParams;
        const quizFrame: any = document.getElementById('quiz');
        quizFrame.src = iframeUrl;
        quizFrame.addEventListener('load', ()=>{
            try {
                quizFrame.style.height = quizFrame.contentWindow.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                quizFrame.style.height = '510px';
            }
        })
        window.addEventListener('resize', ()=>{
            try {
                quizFrame.style.height = quizFrame.contentWindow.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                quizFrame.style.height = '510px';
            }
        })
    }, [])

    return <iframe style={{width: '100%', border: 'unset', minWidth: '100vw'}} id="quiz"></iframe>
}

const PartnerPage = () => {
    const {width} = useWindowSize();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Партнерская программа для агентов и компаний с самозанятыми
                </title>
                <meta
                    name="description"
                    content="Станьте партнером Самозанятые.рф: приводи новых клиентов и получай ежемесячное агентское вознаграждение."
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/partner"
                />
            </Helmet>
            <Layout>
                <div className={styles.containerTop}>
                    <BackgroundPosterCt/>
                    <div className={styles.text}>
                        <TypographyUI.H1
                            swichPoint={LAPTOP_MOBILE_POINTER}
                            className={styles.title}
                            variant="h1"
                        >Зарабатывайте вместе с нами
                        </TypographyUI.H1>

                        <TypographyUI.Body1Regular
                            swichPoint={LAPTOP_MOBILE_POINTER}
                            className={styles.description}
                        >
                            Станьте партнером Самозанятые.рф — расскажите о нашем сервисе и получайте ежемесячный доход
                            за каждого приведенного клиента
                        </TypographyUI.Body1Regular>

                        <ButtonUI $w="240px" className={styles.tryButton} onClick={() => {
                            document.querySelector('#quizSection')?.scrollIntoView({behavior: 'smooth'});
                        }}>Стать партнером</ButtonUI>
                    </div>

                    <div className={styles.background}>
                        <StaticImage
                            className={styles.backgroundImg}
                            src="../../images/partner/background.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={576}
                            height={523}
                            layout="fixed"
                            objectFit="contain"
                            alt="background-img"
                        />
                    </div>
                </div>

                <ButtonOfScrollCt id="partner-guarantee" $w="100%" $mt="120px"/>


                <div className={styles.containerAdditionalService}>

                    <div className={styles.additionalServiceItems}>
                        <div className={styles.additionalServiceItem}>
                            <div className={styles.additionalServiceItemImg}>
                                <StaticImage
                                    src="../../images/partner/how-work.png"
                                    loading="eager"
                                    placeholder="none"
                                    layout="constrained"
                                    objectFit="contain"
                                    alt="how-work"
                                />
                            </div>
                            <div className={styles.additionalServiceItemText}>
                                <TypographyUI.H3 className={styles.additionalServiceItemTitle}>
                                    Как это работает?
                                </TypographyUI.H3>
                                <TypographyUI.Body1Regular
                                    className={styles.additionalServiceItemDescription}
                                >
                                    «Бизнес» — сервис для компаний, которые работают с самозанятыми. Подготовим и
                                    проверим документы, проведем выплаты, сформируем чеки и акты.
                                    <br></br><br></br>Мы берем от 1% комиссии за переводы исполнителям. Если приведете
                                    нам клиента — часть комиссии отдадим вам
                                </TypographyUI.Body1Regular>
                                <Link to="/business">
                                    <ButtonUI.Secondary
                                        className={styles.additionalServiceItemButton}
                                        $w="240px"
                                    >
                                        Подробнее о платформе
                                    </ButtonUI.Secondary>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="partner-guarantee" className={styles.containerServices}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.serviceTitle}
                    >
                        Если станете нашим партнером, мы гарантируем:
                    </TypographyUI.H2>
                    <div className={styles.serviceItems}>
                        <div className={styles.serviceItem}>
                            <div className={styles.serviceItemContainerImg}>
                                <img
                                    className={styles.serviceItemImg}
                                    alt="service-ruble"
                                    src={sRubleImg}
                                />
                            </div>
                            <div>
                                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                                    Ежемесячные выплаты на счет
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.serviceItemDescription}
                                >
                                    В программе нет отложенных на год платежей. Мы посчитаем оборот приведенного клиента
                                    и отправим часть вашей комиссии уже через месяц.*
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                        <div className={styles.serviceItem}>
                            <div className={styles.serviceItemContainerImg}>
                                <img
                                    className={styles.serviceItemImg}
                                    alt="service-upper"
                                    src={sUpperImg}
                                />
                            </div>
                            <div>
                                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                                    Возможность увеличения дохода
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.serviceItemDescription}
                                >
                                    Чем больше оборот компании, тем выше ваш доход. Количество приглашенных компаний не
                                    ограничено
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                        <div className={styles.serviceItem}>
                            <div className={styles.serviceItemContainerImg}>
                                <img
                                    className={styles.serviceItemImg}
                                    alt="service-docs"
                                    src={sDocsImg}
                                />
                            </div>
                            <div>
                                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                                    Простой и прозрачный алгоритм
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.serviceItemDescription}
                                >
                                    Мы заключаем партнерский договор и отправляем акт на каждую выплату бонусов по
                                    программе. Вы получаете официальный доход
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                        <div className={styles.serviceItem}>
                            <div className={styles.serviceItemContainerImg}>
                                <img
                                    className={styles.serviceItemImg}
                                    alt="service-private"
                                    src={sPrivateImg}
                                />
                            </div>
                            <div>
                                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                                    Конфиденциальность
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.serviceItemDescription}
                                >
                                    Партнеры по бизнесу или руководители не узнают о вашей комиссии от их оборота — мы
                                    гарантируем конфиденциальность
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>
                    </div>
                    <div className={styles.serviceBottom}>
                        {width && width > LAPTOP_MOBILE_POINTER && (
                            <ButtonUI $w="240px" className={styles.tryButton} onClick={() => {
                                document.querySelector('#quizSection')?.scrollIntoView({behavior: 'smooth'});
                            }}>Оставить заявку</ButtonUI>
                        )}
                        {width && width <= LAPTOP_MOBILE_POINTER && (
                            <ButtonUI className={styles.tryButton} onClick={() => {
                                document.querySelector('#quizSection')?.scrollIntoView({behavior: 'smooth'});
                            }}>Оставить заявку</ButtonUI>
                        )}
                        <TypographyUI.Body2Regular className={styles.serviceBottomAdditional}>* Единоразовая выплата для
                            физических лиц — через три месяца</TypographyUI.Body2Regular>
                    </div>
                </div>

                <div className={styles.containerWhoBecomePartner}>
                    {width && width > LAPTOP_MOBILE_POINTER && (
                        <div className={styles.whoBecomePartnerGradient}></div>
                    )}
                    <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="28px"
                                     className={styles.whoBecomePartnerTitle}>
                        Кто может стать партнером «Бизнес»
                    </TypographyUI.H2>

                    <div className={[styles.whoBecomePartnerDescription, styles.whoBecomePartnerDescriptionOrganisation].join(' ')}>
                        {width && width > LAPTOP_MOBILE_POINTER && (
                            <TypographyUI.H3 className={styles.whoBecomePartnerDescriptionGeneral}>
                                Руководители компаний: юридические лица и индивидуальные предприниматели
                            </TypographyUI.H3>
                        )}
                        {width && width <= LAPTOP_MOBILE_POINTER && (
                            <TypographyUI.H4Bold className={styles.whoBecomePartnerDescriptionGeneral}>
                                Руководители компаний: юридические лица и индивидуальные предприниматели
                            </TypographyUI.H4Bold>
                        )}

                        <TypographyUI.Body1Regular className={styles.whoBecomePartnerDescriptionAdditional}>
                            Что нужно сделать: привлечь нового клиента в «Бизнес» — рассказать о нашей платформе
                            руководителю другой компании
                        </TypographyUI.Body1Regular>

                        <TypographyUI.Body1Medium className={styles.whoBecomePartnerDescriptionBottom}>
                            Сколько вы заработаете:
                        </TypographyUI.Body1Medium>
                    </div>

                    {width && width > LAPTOP_MOBILE_POINTER && (
                        <div className={[styles.whoBecomePartnerTable, styles.whoBecomePartnerTableOrganisation].join(' ')}>
                            <div className={styles.whoBecomePartnerTableColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableColumnHead}>{tableOrganization.header[0]}</TypographyUI.H4Bold>
                                    {tableOrganization.col1.map((item, i) =>
                                        (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                                    )}
                            </div>
                            <div className={styles.whoBecomePartnerTableColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableColumnHead}>{tableOrganization.header[1]}</TypographyUI.H4Bold>
                                {tableOrganization.col2.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                            <div className={styles.whoBecomePartnerTableColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableColumnHead}>{tableOrganization.header[2]}</TypographyUI.H4Bold>
                                {tableOrganization.col3.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                            <div className={styles.whoBecomePartnerTableColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableColumnHead}>{tableOrganization.header[3]}</TypographyUI.H4Bold>
                                {tableOrganization.col4.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                        </div>
                    )}
                    {width && width <= LAPTOP_MOBILE_POINTER && (
                        <div className={[styles.whoBecomePartnerTableMobile, styles.whoBecomePartnerTableMobileOrganisation].join(' ')}>
                            <div className={styles.whoBecomePartnerTableMobileColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableMobileColumnHead}>{tableOrganization.header[0]}</TypographyUI.H4Bold>
                                {tableOrganization.col1.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableMobileColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                            <div className={styles.whoBecomePartnerTableMobileColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableMobileColumnHead}>{tableOrganization.header[1]}</TypographyUI.H4Bold>
                                {tableOrganization.col2.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableMobileColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                            <div className={styles.whoBecomePartnerTableMobileColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableMobileColumnHead}>{tableOrganization.header[2]}</TypographyUI.H4Bold>
                                {tableOrganization.col3.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableMobileColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                            <div className={styles.whoBecomePartnerTableMobileColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableMobileColumnHead}>{tableOrganization.header[3]}</TypographyUI.H4Bold>
                                {tableOrganization.col4.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableMobileColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                        </div>
                    )}

                    <TypographyUI.Body1Regular className={styles.whoBecomePartnerBottomText}>Первую выплату вы получите
                        на расчетный счет спустя месяц работы компании с платформой <Link to="/business">«Бизнес»</Link></TypographyUI.Body1Regular>


                    <div className={[styles.whoBecomePartnerDescription, styles.whoBecomePartnerDescriptionIndividual].join(' ')}>
                        {width && width > LAPTOP_MOBILE_POINTER && (
                            <TypographyUI.H3 className={styles.whoBecomePartnerDescriptionGeneral}>
                                Штатные и внештатные работники компаний: физические лица
                            </TypographyUI.H3>
                        )}
                        {width && width <= LAPTOP_MOBILE_POINTER && (
                            <TypographyUI.H4Bold className={styles.whoBecomePartnerDescriptionGeneral}>
                                Штатные и внештатные работники компаний: физические лица
                            </TypographyUI.H4Bold>
                        )}

                        <TypographyUI.Body1Regular className={styles.whoBecomePartnerDescriptionAdditional}>
                            Что нужно сделать: убедить руководителя вашей компании воспользоваться сервисом «Бизнес»
                        </TypographyUI.Body1Regular>

                        <TypographyUI.Body1Medium className={styles.whoBecomePartnerDescriptionBottom}>
                            Сколько вы заработаете:
                        </TypographyUI.Body1Medium>
                    </div>

                    {width && width > LAPTOP_MOBILE_POINTER && (
                        <div className={[styles.whoBecomePartnerTable, styles.whoBecomePartnerTableIndividual].join(' ')}>
                            <div className={styles.whoBecomePartnerTableColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableColumnHead}>{tableIndividual.header[0]}</TypographyUI.H4Bold>
                                {tableIndividual.col1.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                            <div className={styles.whoBecomePartnerTableColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableColumnHead}>{tableIndividual.header[1]}</TypographyUI.H4Bold>
                                {tableIndividual.col2.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                        </div>
                    )}
                    {width && width <= LAPTOP_MOBILE_POINTER && (
                        <div className={[styles.whoBecomePartnerTableMobile, styles.whoBecomePartnerTableMobileIndividual].join(' ')}>
                            <div className={styles.whoBecomePartnerTableMobileColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableMobileColumnHead}>{tableIndividual.header[0]}</TypographyUI.H4Bold>
                                {tableIndividual.col1.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableMobileColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                            <div className={styles.whoBecomePartnerTableMobileColumn}>
                                <TypographyUI.H4Bold className={styles.whoBecomePartnerTableMobileColumnHead}>{tableIndividual.header[1]}</TypographyUI.H4Bold>
                                {tableIndividual.col2.map((item, i) =>
                                    (<TypographyUI.Body1Regular key={item} className={styles.whoBecomePartnerTableMobileColumnItem}>{item}</TypographyUI.Body1Regular>)
                                )}
                            </div>
                        </div>
                    )}

                    <TypographyUI.Body1Regular className={styles.whoBecomePartnerBottomText}>Единоразовую выплату вы
                        получите спустя 3 месяца работы компании с платформой <Link
                            to="/business">«Бизнес»</Link></TypographyUI.Body1Regular>


                </div>
                <div className={styles.containerHowBecomePartner}>
                    <TypographyUI.H2 className={styles.howBecomePartnerTitle}>
                        Как стать нашим партнером
                    </TypographyUI.H2>

                    <div className={styles.howBecomePartnerNums}>
                        <div className={styles.howBecomePartnerLine}></div>
                        <div className={styles.howBecomePartnerNumContainer}>
                            <img
                                alt="num1"
                                className={styles.howBecomePartnerNumImg}
                                src={num1Img}
                            />
                            <TypographyUI.Body2Medium
                                className={[styles.howBecomePartnerNumText, styles.howBecomePartnerNumText1].join(' ')}>
                                Отправить заявку
                            </TypographyUI.Body2Medium>
                        </div>
                        <div className={styles.howBecomePartnerNumContainer}>
                            <img
                                alt="num2"
                                className={styles.howBecomePartnerNumImg}
                                src={num2Img}
                            />
                            <TypographyUI.Body2Medium
                                className={[styles.howBecomePartnerNumText, styles.howBecomePartnerNumText2].join(' ')}>Подписать
                                договор партнерской программы</TypographyUI.Body2Medium>
                        </div>
                        <div className={styles.howBecomePartnerNumContainer}>
                            <img
                                alt="num3"
                                className={styles.howBecomePartnerNumImg}
                                src={num3Img}
                            />
                            <TypographyUI.Body2Medium
                                className={[styles.howBecomePartnerNumText, styles.howBecomePartnerNumText3].join(' ')}>Получить
                                деньги на свой счет</TypographyUI.Body2Medium>
                        </div>
                    </div>
                </div>

                <SectionBoxSd id="quizSection">

                    <PartnerQuiz />

                </SectionBoxSd>
            </Layout>
        </>
    );
};

export default PartnerPage;

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  position: relative;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 20px;
  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 20px;
  }
`;
