// extracted by mini-css-extract-plugin
export var additionalServiceItem = "style-module--additional-service-item--34d27";
export var additionalServiceItemButton = "style-module--additional-service-item-button--5a560";
export var additionalServiceItemDescription = "style-module--additional-service-item-description--7da0d";
export var additionalServiceItemImg = "style-module--additional-service-item-img--0c61f";
export var additionalServiceItemText = "style-module--additional-service-item-text--a187c";
export var additionalServiceItemTitle = "style-module--additional-service-item-title--1daed";
export var additionalServiceItems = "style-module--additional-service-items--1fda0";
export var background = "style-module--background--b7ac0";
export var backgroundImg = "style-module--background-img--d2ffc";
export var containerAdditionalService = "style-module--container-additional-service--53f25";
export var containerHowBecomePartner = "style-module--container-how-become-partner--d2e5a";
export var containerServices = "style-module--container-services--a1379";
export var containerTop = "style-module--container-top--63047";
export var containerWhoBecomePartner = "style-module--container-who-become-partner--e9f4b";
export var description = "style-module--description--353d1";
export var howBecomePartnerLine = "style-module--how-become-partner-line--b2264";
export var howBecomePartnerNumContainer = "style-module--how-become-partner-num-container--feaee";
export var howBecomePartnerNumImg = "style-module--how-become-partner-num-img--7096b";
export var howBecomePartnerNumText = "style-module--how-become-partner-num-text--b56c9";
export var howBecomePartnerNumText1 = "style-module--how-become-partner-num-text-1--9ac45";
export var howBecomePartnerNumText2 = "style-module--how-become-partner-num-text-2--722ac";
export var howBecomePartnerNumText3 = "style-module--how-become-partner-num-text-3--8b853";
export var howBecomePartnerNums = "style-module--how-become-partner-nums--83588";
export var howBecomePartnerTitle = "style-module--how-become-partner-title--a7a19";
export var serviceBottom = "style-module--service-bottom--b7ef3";
export var serviceBottomAdditional = "style-module--service-bottom-additional--1f234";
export var serviceItem = "style-module--service-item--57b4a";
export var serviceItemContainerImg = "style-module--service-item-container-img--10f10";
export var serviceItemDescription = "style-module--service-item-description--d99e4";
export var serviceItemImg = "style-module--service-item-img--17421";
export var serviceItemLink = "style-module--service-item-link--c85e5";
export var serviceItemTitle = "style-module--service-item-title--4fc3c";
export var serviceItems = "style-module--service-items--e98b8";
export var serviceTitle = "style-module--service-title--62699";
export var text = "style-module--text--0628f";
export var title = "style-module--title--03c08";
export var tryButton = "style-module--try-button--e950b";
export var whoBecomePartnerBottomText = "style-module--who-become-partner-bottom-text--261cc";
export var whoBecomePartnerDescription = "style-module--who-become-partner-description--2f174";
export var whoBecomePartnerDescriptionAdditional = "style-module--who-become-partner-description-additional--45c9f";
export var whoBecomePartnerDescriptionBottom = "style-module--who-become-partner-description-bottom--3f69f";
export var whoBecomePartnerDescriptionGeneral = "style-module--who-become-partner-description-general--a64ea";
export var whoBecomePartnerDescriptionIndividual = "style-module--who-become-partner-description-individual--61a6f";
export var whoBecomePartnerDescriptionOrganisation = "style-module--who-become-partner-description-organisation--b838a";
export var whoBecomePartnerGradient = "style-module--who-become-partner-gradient--4cb59";
export var whoBecomePartnerTable = "style-module--who-become-partner-table--265f2";
export var whoBecomePartnerTableColumn = "style-module--who-become-partner-table-column--1a1e5";
export var whoBecomePartnerTableColumnHead = "style-module--who-become-partner-table-column-head--63e20";
export var whoBecomePartnerTableColumnItem = "style-module--who-become-partner-table-column-item--a6dc8";
export var whoBecomePartnerTableIndividual = "style-module--who-become-partner-table-individual--88a20";
export var whoBecomePartnerTableMobile = "style-module--who-become-partner-table-mobile--52c83";
export var whoBecomePartnerTableMobileColumn = "style-module--who-become-partner-table-mobile-column--9a592";
export var whoBecomePartnerTableMobileColumnHead = "style-module--who-become-partner-table-mobile-column-head--8a081";
export var whoBecomePartnerTableMobileColumnItem = "style-module--who-become-partner-table-mobile-column-item--187b8";
export var whoBecomePartnerTableMobileIndividual = "style-module--who-become-partner-table-mobile-individual--203c6";
export var whoBecomePartnerTableMobileOrganisation = "style-module--who-become-partner-table-mobile-organisation--2237a";
export var whoBecomePartnerTableOrganisation = "style-module--who-become-partner-table-organisation--8fb55";
export var whoBecomePartnerTitle = "style-module--who-become-partner-title--e1954";